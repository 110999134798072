import React from 'react';

/**
 * 可选参数
 */
interface IProps {
	site?: any;
}

/**
 * 页脚
 */
class LayoutFooter extends React.PureComponent<IProps> {
	/**
	 * render
	 */
	public render(): any {
		return (
			<div className="p-layout-footer">
				<div className="relevant">
					<div className="relevant-list">
						<ul>
							<li>兴趣岛</li>
							<li>
								<a href="/about">公司简介</a>
							</li>
							<li>
								<a href="https://mp.weixin.qq.com/s?__biz=MzU3NDkwODMxMA==&mid=2247485827&idx=1&sn=e4fbff7ee9460b68a549a6908754a66d&chksm=fcbf62e2ff77214475c09bd2880f1c5ed38b8ae3ea9778cf822fce227c7e7b7be1f7a352e031&mpshare=1&scene=1&srcid=1112Rj0gwHzI7JJh4qV11J2r&sharer_shareinfo=7a56bca5fb2a374ef34f44f7f56715e8&sharer_shareinfo_first=7a56bca5fb2a374ef34f44f7f56715e8&from=industrynews&version=4.1.28.8017&platform=win&nwr_flag=1#wechat_redirect">市场合作</a>
							</li>
							<li>
								<a href="https://www.liepin.com/company/8975743">加入我们</a>
							</li>
						</ul>
						<ul>
							<li>联系我们</li>
							<li>电话：400-8328-080（工作日9:30-22:00）</li>
							<li>
								地址：广州市天河区棠东东路25号兴趣岛总部
							</li>
							<li>邮箱：jiamin.deng@qlchat.com <br /></li>
						</ul>
					</div>
					<div className="qr-box-list">
						<div className="qr-box">
							<div className="qr-name">公众号</div>
							<img src="https://img.qlchat.com/qlLive/activity/image/TY799MRE-8VXR-CUX8-1687686865072-F6EYQBMKYT3L.png?x-oss-process=image/format,webp" alt="" />
						</div>
						<div className="qr-box">
							<div className="qr-name">APP下载</div>
							<img src="https://img.qlchat.com/qlLive/activity/image/KHGNAUUL-7X8Q-ZYF5-1709792495337-O1A5DSGEAPD8.png?x-oss-process=image/format,webp" alt="" />
						</div>
					</div>
				</div>
				<div className="copyright">
					<p>copyright C 2016-2022 www.qianliao.net inc. All Right Reserved 广州兴趣岛信息科技有限公司 版权所有</p>
					<p>
						<span>
							<a href="https://beian.miit.gov.cn/">粤ICP备16034932号</a>
						</span>
						<span>
							<a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44010602005588">粤公网安备 44010602005588号</a>
						</span>
						{/* <span>
							<a href="http://img.qlchat.com/qlLive/liveCommon/wangwenzheng.jpg?x-oss-process=image/format,webp">粤网文【2020】4310-268号</a>
						</span> */}
						<span>
							<a href="https://img.qlchat.com/qlLive/activity/image/2QUP81XW-SKBQ-WQDL-1567523544542-3DP73C2P65ON.jpg">增值电信业务经营许可证：粤B2-20191058</a>
						</span>
						<span>
							<a href="http://www.gdis.cn/">互联网清理整顿</a>
						</span>
					</p>
				</div>
			</div>
		);
	}
}

export default LayoutFooter;
