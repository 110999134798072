import { FC, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay } from 'swiper/core';
SwiperCore.use([Autoplay]);
import 'swiper/swiper.less';
import './style.less';

interface IProps {
  classNames?: string
}
/** 品牌报道 */
const BrandReport: FC<IProps> = ({classNames}) => {
  const swipeRef = useRef(null);
  const list = [
	{
		channelImg:'https://img.qlchat.com/qlLive/activity/image/Q63NWZUR-966J-OBYX-1709002656366-QMTFNWE295TM.png?x-oss-process=image/format,webp',
		title:'广州上百人登山练太极，“文化+游学”受关注',
		content:'9月19日，央级媒体新华网发布报道，讲述兴趣岛武当太极游学活动盛况，及其对响应国家号召弘扬中国传统文化的意义。',
		link:'https://app.xinhuanet.com/news/article.html?articleId=2f0eb7588363f1bdc0175cbdd4340640&timestamp=88759',
	},
	{
		channelImg:'https://img.qlchat.com/qlLive/activity/image/EIAY848U-7ZO3-VWHU-1715420664347-5D6TM1XE6D35.png?x-oss-process=image/format,webp',
		title:'兴趣岛亮相2024广州文交会，共同构建多领域创新发展新格局',
		content:'9月27日-29日，2024广州文化产业交易会在广交会展馆举办。期间，兴趣岛展出企业如何通过中华优秀传统文化激发银发一族的兴趣文化空间，并荣获2024年“广州文化企业30强”称号。',
		link:'http://news.china.com.cn/mts/2024-10/08/content_1195793.htm',
	},
	{
		channelImg:'https://img.qlchat.com/qlLive/activity/image/CZPAOS44-3BYB-74AJ-1709002652926-5WP3A2OHX94Q.png?x-oss-process=image/format,webp',
		title:'公益旅拍热度攀升，这场手机摄影大赛初赛百强“出圈”',
		content:'今年9月，2024兴趣岛第三届手机摄影大赛公布初赛赛果，历经为期两个多月紧张的征稿、评审环节，600名选手在初赛中脱颖而出，获6个赛道“百强奖”，并在线分享手机摄影学习心得和作品立意。',
		link:'https://cn.chinadaily.com.cn/a/202409/19/WS66ebdc08a310a792b3abceb8.html',
	},
	{
		channelImg:'https://media.qianliaowang.com/qlLive/activity/image/FP6VEO4D-ZVAW-TIP8-1731044852473-QQ9DOL5QHH4Z.png?x-oss-process=image/format,webp',
		title:'兴趣岛受邀参加2024中国数字文娱大会',
		content:'今年8月，2024年中国数字文娱大会在广州市天河区开幕，广州兴趣岛信息科技有限公司（以下简称兴趣岛）受邀参加，体现了文娱行业和政府单位对兴趣岛创新实力和行业影响力的认可。',
		link:'https://app.ceweekly.cn/h5/#/pages/detail/articleDetail/articleDetail?id=453025&share=1',
	},
	{
		channelImg:'https://media.qianliaowang.com/qlLive/activity/image/4731W64J-5PRQ-IAWU-1731044854422-NBBA1F1Y211S.png?x-oss-process=image/format,webp',
		title:'兴趣岛终南山武当太极活动：边游边学，让更多人感受太极之美',
		content:'10月18日，兴趣岛武当太极终南山站“强身心溯文化共作武当太极传承人”活动今年已举办第三次，本次活动带领近百位银龄人群感受武当太极非遗之美，被国家文旅部直属媒体《文旅中国》深度报道并高度评价本次活动让传统武术有了新的“打开方式”。',
		link:'https://share.ccmapp.cn/shareDetail?1=1&action=opendetail%3Brichtext%3B671e0bdc56be8b58b018b24e&siteId=2',
	},
	{
		channelImg:'https://media.qianliaowang.com/qlLive/activity/image/MLOPCT67-GBK4-NWUO-1731044850458-CIQ5JIPXS6PS.png?x-oss-process=image/format,webp',
		title:'东方智慧与现代生活深度融合，兴趣岛武当太极蔚然成风',
		content:'10月30日，中宣部新华社《半月谈》发长文深度报道兴趣岛武当太极是东方智慧与现代生活深度融合，据悉，《半月谈》被誉为"中华第一刊"，是是中宣部委托新华社主办的重要党刊，本报道对于兴趣岛媒体传播极具影响力。',
		link:'https://mp.weixin.qq.com/s/Dc6zZ_Nma7hhxnLwMv65DQ',
	},
  ];

  const onSlidePrev = ()=>{
    swipeRef.current?.slidePrev?.();
  }

  const onSlideNext = ()=>{
    swipeRef.current?.slideNext?.();
  }
  
  const changeAutoPlay = () => {
    if(!swipeRef.current.autoplay.running){
      swipeRef.current.autoplay?.start?.();
    }else{
      swipeRef.current.autoplay?.stop?.();
    }
  }

  const getSwipeInstance = (swiper)=>{
    swipeRef.current = swiper;
  }

	return (
		<div className={`home-brand-report-container ${classNames}`}>
      <div className="brand-report-list">
        <div className='brand-report-prev flex-center' onClick={onSlidePrev}><i className="iconfont iconxiaojiantou to-left"></i></div>
        <div className='brand-report-next flex-center' onClick={onSlideNext}><i className="iconfont iconxiaojiantou"></i></div>
        <Swiper
          onSwiper={getSwipeInstance}
          // navigation={true}
          slidesPerView={'auto'}
          loop={true}
          observer
          observeParents
          observeSlideChildren
          centeredSlides
          spaceBetween={48}
          // autoplay={{
          //   delay: 3000,
          //   disableOnInteraction: false,
          // }}
          className="brand-report-swiper">
          {list.map((item: any, index: number): any => (
            <SwiperSlide key={index} className="brand-report-slide">
              <div
                className="brand-report-item"
                key={index}
                onMouseOver={changeAutoPlay}
                onMouseOut={changeAutoPlay}
                onClick={() => window.open(item?.link)}
                >
                <div className='report-item-wrap'>
                  <img className='channel-img' src={item.channelImg} />
                  <div className='title'>{item.title}</div>
                  <div className='content'>
                    {item.content}
                  </div>
                </div>
                <div className='view-detail-text flex-center'>
                  查看详情
                  <i className="iconfont iconxiaojiantou"></i>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
		</div>
	);
};
export default BrandReport;
