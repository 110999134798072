/**
 * @description 视频弹窗
 */
import React, { useEffect, useState, useCallback } from 'react';
import ReactDOM from 'react-dom';
import Hls from 'hls.js';
import './style.less';

const DialogVideo: any = (props): any => {
	const [videoRef, setVideoRef]: any = useState(null);

	// 自动播放
	useEffect((): any => {
		if (videoRef) {
			let url: any = props.resourceURL;
			if (url && /\.mp4/.test(url)) {
				videoRef.src = url;
				videoRef.play();
			} else if (url && url.includes('.m3u8')) {
				if (videoRef.canPlayType('application/vnd.apple.mpegurl')) {
					videoRef.src = url;
					videoRef.play();
				} else {
					if (Hls.isSupported()) {
						let hls: any = new Hls();
						hls.loadSource(url);
						hls.attachMedia(videoRef);
						hls.on(Hls.Events.MANIFEST_PARSED, (): any => {
							console.log('可以开始播放了');
						});
						videoRef.play();
					} else {
						console.log('此浏览器不支持视频流播放');
					}
				}
			} else {
				console.warn('视频地址不存在！！');
			}
		}
	}, [videoRef]);

	return (
		<div
			className="video-dialog-box-neo"
			// onClick={(): void => {
			// 	props.onClose();
			// }}
			>
			<div className="dialog-contain-neo">
				<video
					ref={(ref): any => {
						setVideoRef(ref);
					}}
					className={'video-contain-neo'}
					preload="auto"
					autoPlay={false}
					loop
					x5-video-player-type="h5"
					x5-video-player-fullscreen="true"
					x5-video-orientation="landscape"
					poster={props.cover}
					controls
					onClick={(e): void => {
						e.preventDefault();
						e.stopPropagation();
					}}
				/>
				<img
					className="close"
					src="https://img.qlchat.com/qlLive/activity/image/BR7LHWCJ-4KG7-ONPU-1710224623937-PTUWYR4Q3WUQ.png?x-oss-process=image/format,webp"
					onClick={(): void => {
						props.onClose();
					}}
				/>
			</div>
		</div>
	);
};

/**
 * @description 弹窗渲染
 */
const renderDom: any = (components, props): any => {
	let rootEle: any = document.getElementById('dialog-components-body');
	if (!rootEle) {
		rootEle = document.createElement('div');
		rootEle.id = 'dialog-components-body';
		document.body.appendChild(rootEle);
	}
	ReactDOM.render(React.createElement(components, props), rootEle);
};

/**
 * @description 关闭渲染
 */
const colseDom: any = (): any => {
	let rootEle: any = document.getElementById('dialog-components-body');
	if (rootEle) {
		ReactDOM.render(null, rootEle);
		rootEle.parentNode.removeChild(rootEle);
	}
};

/**
 * @description 开始弹窗
 */
const playVideoDialog: any = (props): any => {
	// 1. 关闭弹窗操作
	const closeDialog: any = (): any => {
		colseDom();
		window.removeEventListener('popstate', closeDialog, null);

		return false;
	};
	// 2.弹窗数据
	const obj: any = {
		// 关闭检测弹窗
		onClose: closeDialog,
		resourceURL: props.resourceURL,
		cover: props.cover,
	};
	// 3.显示检测弹窗
	renderDom(DialogVideo, obj);
};

export default {
	playVideoDialog,
};
