import React, { FC } from 'react';
import './style.less';

/**
 * 可选参数
 */
interface IProps {
	classNames?: string;
}

/**
 * 荣誉奖项
 */
const HonoraryPan:FC<IProps> = (props)=>{
  const {classNames} = props;
  
  const honoraryList = [
    'https://img.qlchat.com/qlLive/activity/image/12RPERCE-EPKH-R89G-1710139658602-HHKVMCH4V5SY.png?x-oss-process=image/format,webp',
    'https://img.qlchat.com/qlLive/activity/image/YYQQN5A5-A4R9-LSMU-1710139693968-26GDNHI6E2YE.png?x-oss-process=image/format,webp',
    'https://img.qlchat.com/qlLive/activity/image/C5PRTBTE-DPWL-WWLJ-1710139706643-MM7NPTEBZG9V.png?x-oss-process=image/format,webp',
    'https://img.qlchat.com/qlLive/activity/image/8E2DVM7N-JIOU-ACW2-1710139710114-RBVOODKYDSXX.png?x-oss-process=image/format,webp',
    'https://img.qlchat.com/qlLive/activity/image/2I54DYM6-B3C9-DOUA-1710139719798-953UKKDPYBIK.png?x-oss-process=image/format,webp',
    'https://img.qlchat.com/qlLive/activity/image/XOTM83KI-IMDG-FA9N-1710139722380-3NSM7D2ET93N.png?x-oss-process=image/format,webp',
    "https://img.qlchat.com/qlLive/activity/image/6HIWAN2V-TUDT-212W-1710140198920-FEBK4LXNZPKN.png?x-oss-process=image/format,webp",
    'https://img.qlchat.com/qlLive/activity/image/WVRYI2BM-6IFI-XON1-1710139782066-4W87Q6JYCDAD.png?x-oss-process=image/format,webp',
  ]
  const honoraryList2 = [
    'https://img.qlchat.com/qlLive/activity/image/JM6J9X8A-LGTP-QYSE-1710139793308-QOTYYPGP299Y.png?x-oss-process=image/format,webp',
    'https://img.qlchat.com/qlLive/activity/image/NG4X6YIM-OAZ1-NFCD-1710139807326-9AOIR5YSEVCI.png?x-oss-process=image/format,webp',
    'https://img.qlchat.com/qlLive/activity/image/L7HQTUM6-KFY6-F43J-1710139812350-NTN3LHHR3LFK.png?x-oss-process=image/format,webp',
    'https://img.qlchat.com/qlLive/activity/image/TZ58CKEK-H6HU-DEXS-1710139814923-B8VWL6SD1DCM.png?x-oss-process=image/format,webp',
    'https://img.qlchat.com/qlLive/activity/image/2UV6W43O-12OQ-IGQA-1710139817390-L95OTJM1HM4X.png?x-oss-process=image/format,webp',
    'https://img.qlchat.com/qlLive/activity/image/8ACZSBPE-RJD9-UCL8-1710139823118-6KNI3Z2G9DJR.png?x-oss-process=image/format,webp',
    'https://img.qlchat.com/qlLive/activity/image/8HJSDPJJ-EHFH-AAB5-1710139825234-AOYTXCNEAXJP.png?x-oss-process=image/format,webp',
    'https://img.qlchat.com/qlLive/activity/image/BXZ8Y8LV-GOWQ-NVPM-1710139828935-2685FHTRQZ47.png?x-oss-process=image/format,webp',
  ]

  return <div className={`honorary-pan-container ${classNames}`}>
    <div className='liner-pan'>
      {
        honoraryList.map((url,index)=>{
          return <img className='liner-pan-item' src={url} key={index} />
        })
      }
      {/* 无感知衔接第一屏 */}
      {
        honoraryList.slice(0,honoraryList.length/2).map((url,index)=>{
          return <img className='liner-pan-item' src={url} key={index} />
        })
      }
    </div>
    <div className='liner-pan'>
      {
        honoraryList2.map((url,index)=>{
          return <img className='liner-pan-item' src={url} key={index} />
        })
      }
      {
        honoraryList2.slice(0,honoraryList2.length/2).map((url,index)=>{
          return <img className='liner-pan-item' src={url} key={index} />
        })
      }
    </div>
  </div>
}

export default HonoraryPan;
