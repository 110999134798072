import React, { useState, useEffect, useRef } from 'react';
import Head from 'next/head';
import Toast from '../../../../components/toast/ToastIndex';

// /* 引入通用样式 */
import '../../../../lib/assets/styles/StyleCommon.less';
import '../../../../lib/assets/styles/StyleReset.less';
import '../../../../lib/assets/styles/StyleFlex.less';

import './LayoutStyle.less';
import LayoutHead from './LayoutHead';
import LayoutFooter from './LayoutFooter';

/** Page props */
interface IPageProps {
	title?: string;
	bodyClass?: string;
	isroi?: string;

	[index: string]: any;

	resetClass?: string;
	topicListEl?: any;
	region?: string;
	activeTab?: string;
	tabArr?: any;
	tabClick?: any;
}

const Page: any = ({ bodyClass, title, pageDescription, pageKeyword, children, resetClass = '', topicListEl, region, activeTab = '', tabArr, tabClick }: IPageProps): any => {
	const [pageTitle, setPageTitle]: any = useState(title);
	const [qrDialog, setQrDialog]: any = useState(null);
	const [qrCode, setQrCode]: any = useState(null);
	const [showTop, setShowTop]: any = useState(false);
	const [showToast, setShowToast]: any = useState(null);

	// 动态修改菜单栏背景色
	useEffect(()=>{
		const headElement = document.querySelector('#p-layout-head');
		const changeNavColor = (e: any) => {
			const scrollTop = e.target.nodeType === 9 ? e.target.documentElement.scrollTop : e.target.scrollTop;
			if(scrollTop === 0){
				headElement.classList.remove('white-bg');
			}else{
				headElement.classList.add('white-bg');
			}
		}
		document.addEventListener('scroll', changeNavColor);
		return () => {
				document.removeEventListener('scroll', changeNavColor);
		};
	},[])

	useEffect((): void => {
		setPageTitle(title);
	}, [title]);

	// didmount
	useEffect((): void => {
		// baseService
		// 	.commonApi({
		// 		url: '/h5/system/getOpenConfigValue',
		// 		body: { key: 'QR_CODE_PC_OFFICIAL_QL_CLASS' },
		// 	})
		// 	.then((res: any): void => {
		// 		setQrCode(getVal(res, 'data.value', null));
		// 	});

		setTimeout((): void => {
			if (typeof _qla !== 'undefined') {
				_qla('pv', {});
				_qla.bindVisibleScroll('scroll-content-container');
				_qla.bindVisibleScroll('co-scroll-to-load');
				_qla.bindVisibleScroll('pc-warp ');
			}
		}, 1500);
	}, []);

	if (typeof window !== 'undefined') {
		const toastAction: any = (text: string, during?: number, bigFont?: boolean): any => {
			setShowToast({
				toastShow: true,
				toastText: text,
				bigFont,
			});
			setTimeout((): any => {
				setShowToast({
					toastShow: false,
					toastText: text,
					bigFont,
				});
			}, during || 1000);
		};

		window.showQr = (qr: any): void => {
			if (qr && !qr.qrUrl) {
				qr.qrUrl = qrCode;
			}
			setQrDialog(qr);
		};
		window.toast = toastAction;
		const toggleShow: any = (isShow: boolean): void => {
			if (isShow !== showTop) {
				setShowTop(isShow);
			}
		};
		document.addEventListener('scroll', (e: any): void => {
			const sTop: any = document.body.scrollTop + document.documentElement.scrollTop;

			if (sTop > document.body.clientHeight) {
				toggleShow(true);
			} else {
				toggleShow(false);
			}
		});
	}

	const toTop: any = (): void => {
		(document as any).body.scrollTop = 0;
		(document as any).documentElement.scrollTop = 0;
	};

	return (
		<React.Fragment>
			<div className={`pc-warp p-layout-index ${resetClass ? ` ${resetClass}` : ''}`}>
				<Head>
					<title>{pageTitle}</title>
					<meta name="description" content={pageDescription} />
					<meta name="keywords" content={pageKeyword || pageTitle} />
					<meta property="og:title" content={pageTitle} />
					<meta property="og:description" content={pageDescription} />
				</Head>
				<LayoutHead activeTab={activeTab} topicListEl={topicListEl} region={region} tabClick={tabClick} tabArr={tabArr} />
				<div className={`p-layout-body ${bodyClass}`}>{children}</div>
				<LayoutFooter />

				{qrDialog && qrDialog.qrDesc && qrDialog.qrUrl ? (
					<div className="p-layout-dialog qr-dialog">
						<div className="_bg" onClick={(): void => setQrDialog(null)}></div>
						<div className="_container">
							<div className="dialog-close p-icon-close" onClick={(): void => setQrDialog(null)}></div>
							<p className="dialog-title">欢迎扫码</p>
							<div className="qr-content">
								<img src={qrDialog.qrUrl} alt="" />
							</div>
							<p className="qr-tips">{qrDialog.qrDesc}</p>
						</div>
					</div>
				) : null}

				{showTop ? <div className="p-to-top-btn" onClick={toTop}></div> : null}
				{showToast ? (
					<div className={`p-toast ${showToast.toastShow ? '' : 'closed'}`}>
						<div className="bd">
							<p className="cnt">{showToast.toastText}</p>
						</div>
					</div>
				) : null}
			</div>
		</React.Fragment>
	);
};

export default Page;
