import { FC } from "react";
import './style.less';

interface IProps{

}
const FixedRightTools:FC<IProps> = ()=>{

	/**
	 * 回到顶部
	 */
	const toTopFunc = ()=> {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}
  return <>
    <div className="home-training-float-icon-box">
					<div className="home-training-float-icon">
						<div className="float-icon-item">
							<img className="float-icon-item-img" src="https://img.qlchat.com/qlLive/activity/image/GCXYB24S-77ED-BN3B-1629882482182-FBZYDEOCERK5.png?x-oss-process=image/format,webp" />
							<div className="float-icon-item-text">扫码下载</div>
							<div className="float-icon-item-qrcode">
								<img className="float-icon-item-qrcode-img" src="https://img.qlchat.com/qlLive/activity/image/SV8V8WAX-EBY8-VJE8-1702605215787-D3G38JMEBNVF.png?x-oss-process=image/format,webp" />
								<div className="float-icon-item-qrcode-text">兴趣岛APP</div>
							</div>
						</div>
						<div className="float-icon-item">
							<img className="float-icon-item-img" src="https://img.qlchat.com/qlLive/activity/image/WSD9MZB3-JJ3K-GJUU-1629882586959-Y1TX1Z2YX185.png?x-oss-process=image/format,webp" />
							<div className="float-icon-item-text">在线咨询</div>
							<div className="float-icon-item-qrcode">
								<img className="float-icon-item-qrcode-img" src="https://img.qlchat.com/qlLive/activity/image/RAX12IFF-WZPR-I92B-1704446054282-GYC46R4SIBHA.png?x-oss-process=image/format,webp" />
								<div className="float-icon-item-qrcode-text">官网客服二维码</div>
							</div>
						</div>
						<div className="float-icon-item">
							<img className="float-icon-item-img" src="https://img.qlchat.com/qlLive/activity/image/BI4ROCNB-PEON-L6WG-1653900039557-7FHIJ1IFXYSG.png?x-oss-process=image/format,webp" />
							<div className="float-icon-item-text">资料领取</div>
							<div className="float-icon-item-qrcode let-rest">
								<img className="float-icon-item-qrcode-img" src="https://img.qlchat.com/qlLive/activity/image/D2L1HNHT-7COD-NH7Q-1687745116518-A9MUBAR951LH.png?x-oss-process=image/format,webp" />
								<div className="float-icon-item-qrcode-text">扫码免费领取必读好书</div>
							</div>
						</div>
					</div>

					<div className="scroll-top-button" onClick={toTopFunc}>
						<img className="float-icon-item-img" src="https://img.qlchat.com/qlLive/activity/image/DDTVA4VW-AQ8F-6CAO-1653900668791-ZRC7DCNTAQLF.png?x-oss-process=image/format,webp" />
						<div className="float-icon-item-text">置顶</div>
					</div>
				</div>
  </>
}

export default FixedRightTools;